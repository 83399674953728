import jquery from "jquery";
export default (window.$ = window.jQuery = jquery);

$('.modal__continue').on('click', function() {
	if($(this).find('.finish-line').length) {
		// Finished state
	} else {
		const currentInner = $(this).closest('.modal__content__inner');
		currentInner.hide();
		currentInner.next().toggle();
	}
});

$('.content-radio__item').on('click', function() {
	$('.content-radio__item').each(function() {
		if($(this).hasClass('active')) {
			$(this).removeClass('active');
		}
	});

	$(this).addClass('active');
});

$('.js-toggle-newsletter').on('click', function() {
	$('.modal--newsletter').addClass('active');
});

$('.js-toggle-event-filters').on('click', function(e) {
	e.preventDefault()
	$('.modal--event-filters').addClass('active');
});

$('.js-toggle-news-filters').on('click', function() {
	$('.modal--event-filters').addClass('active');
});

$('.js-toggle-insight-filters').on('click', function(e) {
	e.preventDefault();
	$('.modal--event-filters').addClass('active');
});

$('.js-toggle-vacancies-filters').on('click', function(e) {
	e.preventDefault();
	$('.modal--event-filters').addClass('active');
});

$('.js-toggle-contact').on('click', function() {
	$('.modal--contact').addClass('active');
});

$('.js-toggle-event').on('click', function() {
	$('.modal--event').addClass('active');
});

const buttons = document.querySelectorAll('.modal__closeTrigger');
buttons.forEach((button) => {
	button.addEventListener('click', (event) => {
		event.preventDefault(); // Prevent default behavior of the button
		const grandparent = button.closest('.modal');
		grandparent.classList.remove('active')
	});
});

$('.finish-line').on('click', function() {
	$(this).closest('.modal').removeClass('active');
	$('.modal__content__inner').hide();
	$('.modal__content .modal__content__inner:first-child').toggle();
})