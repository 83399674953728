// THIS IS HOW TO DO SOMETHING IN JS *AFTER* THE SCRIPTS ARE LOADED
document.addEventListener('vite-script-loaded', function (e) {
    if (e.detail.path === 'src/js/app.ts') {
        // The script with the path src/js/app.ts is now loaded, do whatever initialization is needed
    }
});

// This is run after blitz has injected it's stuff


    screen.width

    document.querySelector('.mainNav__burger').addEventListener('click', function(e) {
        e.preventDefault();
        document.querySelector('.mainNav__container').classList.toggle('mainNav__container--open');

        //TOGGLE OVERFLOW HIDDEN ON BODY ELEMENT
        document.querySelector('body').classList.toggle('overflow-hidden');
    });

    // CLOSE THE MOBILE NAV
    document.querySelector('.mainNav__burgerClose').addEventListener('click', function(e) {
        e.preventDefault();
        document.querySelector('.mainNav__container').classList.toggle('mainNav__container--open');
        // CLOSE ANY OPEN SUB-NAVS IN THE MOBILE MENU
        document.querySelector('.mainNav__container').classList.remove('mainNav__container--scrolled');
        document.querySelector('body').classList.toggle('overflow-hidden');

    });
    // IF SOMEONE CLICKS ON THE TOP LEVEL NAV THAT HAS CHILDREN, THEN WE SHOW THE CHILDREN
    document.querySelectorAll('.dropped__parent--top > a').forEach(elem => {
        elem.addEventListener('click', function(e) {
            if(window.innerWidth < 1024 ){
                e.preventDefault();
            }
            elem.parentElement.classList.toggle('dropped__parent--open');
            document.querySelector('.mainNav__container').classList.toggle('mainNav__container--scrolled');
        });
    });

    // HANDLE THE BACK BUTTON ON THE SUB NAV
    document.querySelectorAll('.dropped__back').forEach(elem => {
        elem.addEventListener('click', function(e) {
            e.preventDefault();
            document.querySelector('.dropped__parent--open').classList.remove('dropped__parent--open');
            document.querySelector('.mainNav__container').classList.toggle('mainNav__container--scrolled');
        });
    });



