const accordionHeaders = document.querySelectorAll('[data-accordion-header]');
//console.log(accordionHeaders)
accordionHeaders.forEach(function (accordionHeader) {
    //console.log('were looping')
    let target = accordionHeader.parentElement.nextElementSibling;
    accordionHeader.onclick = () => {
        //console.log('were clicking')
        let expanded = accordionHeader.getAttribute('aria-expanded') === 'true' || false;
        accordionHeader.setAttribute('aria-expanded', !expanded);
        target.hidden = expanded;
    }
})