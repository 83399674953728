// Homemade version of the AOS library :)
// Just doing some animations on scroll here

document.addEventListener("DOMContentLoaded", function() {
    let animatedElements = document.querySelectorAll('.animate-on-scroll, .animate-on-scroll--header');

    let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // The element is in the viewport
                let animationSpeed = entry.target.getAttribute('data-speed') || '0.5s';
                entry.target.style.transition = `opacity ${animationSpeed}, transform ${animationSpeed}`;
                entry.target.classList.add('visible');
            }
            // If you want the animation to play every time the element comes into view,
            // you can also remove the 'visible' class when it's not intersecting.
            // else {
            //     entry.target.classList.remove('visible');
            // }
        });
    });

    animatedElements.forEach(element => {
        observer.observe(element);
    });
});